import React, { Component } from 'react';
import './footer.css';
import linkedin from './images/linkedin.svg';
import twitter from './images/twitter.svg';
import mail from './images/mail.svg';
import github from './images/github.svg';

export class Footer extends Component {
  getCopyrightText(begin, end) {
    return (
      <span>Copyright &copy; {begin} - {end} - Good Guy Consulting, Inc. - All Rights Reserved.</span>
    );
  }

  render() {
    const copyrightText = this.getCopyrightText(2008, new Date().getFullYear());

    return (
      <div className="app-section">

        <div className="container row footer-item">
          <a
            href="http://www.linkedin.com/company/good-guy-consulting"
            rel="noopener noreferrer"
            target="_blank"
            title="Good Guy Consulting on LinkedIn"
          >
            <img alt="LinkedIn icon" className="icon" src={linkedin} />
          </a>

          <a
            href="https://twitter.com/goodguyinc"
            rel="noopener noreferrer"
            target="_blank"
            title="Good Guy Consulting on Twitter"
          >
            <img alt="Twitter icon" className="icon" src={twitter} />
          </a>

          <a
            href="mailto:contact@goodguyconsulting.com?subject=Contact from website"
            rel="noopener noreferrer"
            target="_blank"
            title="Email Good Guy Consulting"
          >
            <img alt="Email icon" className="icon" src={mail} />
          </a>

          <a
            href="https://github.com/jeffknutson/GoodGuyConsultingPublic"
            rel="noopener noreferrer"
            target="_blank"
            title="Good Guy Consulting on GitHub"
          >
            <img alt="GitHib icon" className="icon" src={github} />
          </a>
        </div>

        <div className="row footer">
          <div className="copyright-container footer-item">
            <p className="copyright-text">{copyrightText}</p>
          </div>
        </div>
      </div>
    );
  }
}
