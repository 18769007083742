import React, { Component } from 'react';
import headerImage from './site-header-med.jpg';

import { Footer } from './footer';

import './App.css';

class App extends Component {

  render() {
    return (
      <div className="App container">

        <header className="App-header header-container">
          <div className="row">
            <img
              alt="Good Guy Consulting Banner"
              className="banner img-fluid header-image"
              src={headerImage}
              title="Good Guy Consulting"
            />
          </div>
        </header>

        <main id="body" className="content" role="main">

          <div className="row">
            
            <div className="col-centered lead">
              <p>
                Founded in 2008 by Jeff Knutson, <strong>Good Guy Consulting</strong> specializes in building high quality, custom applications for the web and mobile platforms.
              </p>

              <p>
                Headquarted in Minneapolis, MN, Good Guy Consulting works with all sizes of clients from small business up to Fortune 500 companies such as Best Buy, Thomson Reuters, 3M, Target, Honeywell, Dow Jones, and many more.
              </p>

              <p>
                <a href="mailto:contact@goodguyconsulting.com?subject=Let's work together">Contact Good Guy Consulting</a> today to find out how we can work together to build the software your company needs!
              </p>
            </div>
          </div>

        </main>

        <footer className="footer-container">
          <Footer />
        </footer>
      </div>
    );
  }
}

export default App;
